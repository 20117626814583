.wrapper-order-history-id{
    border: 1px solid #ced4da;
    padding: 20px 30px;
    margin-bottom: 20px;
}
.order-history-thumb-item{
    height: 140px;
    width: 120px;
    margin: 0px 10px;
    

}
.order-history-thumb-item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.wrapper-order-history-id .btn-dark{
    width: 75%;
    margin-top: 20px;
}

@media screen and (max-width: 992px){
    .wrapper-order-history-id{
        padding: 5px;
    }
    .order-history-thumb-item{
        height: 120px;
        width: 100px;
        margin: 10px 5px 10px 0px;
    }
    .wrapper-order-history-id .btn-dark{
        font-size: 12px;
        width: 100%;
        margin-bottom: 15px;
        margin-top: 15px;
    }

}
