/* .order-proc-item-details{
    font-size: 14px;

} */

.item-details-bold-sm{
    font-size: 13px;
    font-weight: 500;
}
.item-details-sm{
    font-size: 13px;
}


.item-image-sm {
    display: flex;
    justify-content: flex-end;
    align-items: center;
      
}
.item-image-sm img {
    display: block;
    height: 70px;
    width: 70px;   
    object-fit: cover;
   
}
.totals-sm{
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
}

/* .line-item-details{
    font-weight: 500;
}
.price-color {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
} */
