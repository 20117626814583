.side-navbar li {
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 13px;
}
.side-navbar li a:hover{
    opacity: 0.5;
}

.side-navbar h6::after{
    content: "";
    display: block;
    width: 60%;
    padding-top: 10px;
    border-bottom: 1px solid rgba(65,67,68, 0.3);
}

/* .active { 
    padding-left: 20px;
    
} */
