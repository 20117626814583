.form-label{
    font-size: 14px !important;
    margin-bottom: 0.3rem !important;
    font-weight: 500 !important;
}

.validation-code{
    box-shadow: 0 0 8px #b4b5b8;
    /* border: 1px solid #414344; */
    margin: 5px 5px 5px 10px;
    padding: 15px 10px;
    
}
.validation-code-btn button {
    background-color: #414344;
    color: white;
}
.validation-code-message{
    font-size: 14px;
    color: #305cb6;
}