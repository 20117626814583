
.footer-wraper {
    background-color:#414344;
    padding: 30px 0px;

}
.footer-title {
    font-size: 14px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
  
}
ul {
    padding-left: 0px;
    list-style-type: none;
    
}

.footer-wraper li a {
    display: block;
    font-size: 14px;
    color:white;
    text-decoration: none;
    padding-bottom: 5px;
}

.footer-input input{
    width: 300px;
    height: 40px;
    margin-right: 5px;
    border-radius: 3px;
    border:none;
}

.footer-input button{
    width: 100px;
    height: 40px;
    font-weight: 500;
    border-radius: 3px;
    border: none;
    background-color: #fff;
}
.copyright {
    color: white;
    font-size: 12px;
}

@media (max-width: 768px){
    .footer-title, .footer-wraper li a{
        font-size: 13px;
    }

}