.header-search{
    position: relative;
}

.wrapper-search-result{
    position: absolute;
    top: calc(100% + 5px);
    right: 0px;
    width: 470px;
    /* height: 100px; */
    z-index: 40;
    background-color: #fff;
}

.header-search .btn-outline-secondary{
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    border-radius: 0rem;
    /* border-left: none !important; */
    /* transition: none !important; */
}

.header-search .btn-outline-secondary:hover, .header-search .btn-outline-secondary:focus{
    color: #6c757d;
    box-shadow:none !important;
}

.header-search .form-control {
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
    /* border-right: none !important; */
    

}
.header-search .form-control:hover, .header-search .form-control:focus{
    box-shadow: none !important;
    border:  1px solid #ced4da !important;
    /* border-right: none !important; */

}


@media screen and (max-width: 767px){
    .wrapper-search-result{
        width: 100%;
        
    }

}