.wrapper-carousel{      
    height: 450px;
    padding-left: 0px;
    padding-right: 0px;
    
}
.carousel-control-next-icon, .carousel-control-prev-icon{
    width: 1.5rem;
    height: 1.5rem;
}
.carousel-indicators [data-bs-target] {
    width: 20px;
    height: 2px;
}

.img-crop{
    height: 450px !important;
    object-fit: cover;
}

@media screen and (max-width: 425px){
    .wrapper-carousel{
        height: 380px !important;
    }
}