.descr-code{
    font-size: 14px;
}
.wrapper-carousel{
    height: 450px;
}
.btn-dark{
    background: #414344 !important;
    padding: 10px !important;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid #414344;
    border-radius: 0rem;
}

.btn-dark:focus, .btn:focus{
    border: 2px solid darkgray !important;
}


.size-variation{
    position: relative;
    height: 35px;
    width: 35px;
    background-color: white;
    font-size: 14px;
    color: black;
    padding: 6px;
    /* background-color: #bbb; */
    border-radius: 50%;
    border: 1px solid #414344;
    margin-right: 16px;
    display: inline-block;
}

/* .tooltiptext {
    visibility: hidden;
    width: 65px;
    top: 130%;
    left: 30%;
    margin-left: -25px;
    background-color: white;
    color: black;
    border: 1px solid #ced4da;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size: 12px;
    position: absolute;
    z-index: 1;
  } */

  
  /* .color-variation:hover .tooltiptext {
    visibility: visible;
  } */
.selected-size{
    background-color: #414344;
    color: white
}
.shop-list-btn{
    width: 75%;
}

.product-name{
    font-size: 20px;
    font-weight: 600;
    
}

.card-price-list-item .product-price{
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}

.card-price-list-item .product-price-sale{
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;    
    color: red;
    padding-left: 6px;
}

.item-variants, .item-availible, .item-availible-terms, .item-for-order, .item-sizes, .wrapper-conditions{
    font-size: 14px;
}

.item-for-order{
    color: #F25278;

}
@media screen and (max-width: 991px){
    .shop-list-btn{
        width: 100%;
        
    }

}

@media screen and (max-width: 767px) {
    .product-name {
        font-size: 18px;
    }
}



