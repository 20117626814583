.header-order-history{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
}

.wrapper-order-history-details{
    box-shadow: 0 0 3px #0000001f;
    margin-bottom: 40px;

}
.order-history-details{
    min-height: 350px;
    padding: 20px; 
}

.order-history-details h6{
    margin-top: 5px;
}
.order-history-delivery-details div{
    font-size: 14px;
    padding-bottom: 5px;
}


.order-history-thumb{
    height: 100px;
}
.order-history-thumb img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    
}
.costs-info {
    border-bottom: 1px solid #dee2e6;
    min-height: 300px;
}
@media screen and (max-width: 992px){
    .order-history-thumb{
        height: 90px;
    }
    .header-order-history{
        font-size: 14px;
    }
}
@media screen and (max-width: 767px){
    .costs-info {
        border-top: 1px solid #dee2e6;
        padding-top: 15px;
    }
    .order-history-thumb{
        height: 120px;
        margin-left: 8px;
    }
    .wrapper-order-history-details .border-end{
        border-right: none !important;
    }
    


}
