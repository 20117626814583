.wrapper-about-us{
    display: flex;
    align-items: center;
    min-height: 450px;  
    padding: 20px 20px;
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
}

@media screen and (max-width: 991px){
    .wrapper-about-us {
        font-size: 15px;
    }
    
}

