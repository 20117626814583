.item-image {
    height: 120px;
} 
.item-image img {
    display: block;   
    width:70%;   
    object-fit: cover;
   
}

.item-details-bold-lg{
    font-size: 16px;
    font-weight: 500;
    /* margin-top: 20px; */
}
.line-item-name{
    margin-top: 10px;
}

.item-details-lg{
    font-size: 16px;
}

.remove-line-item{
    font-size: 16px;
    color: grey;
    letter-spacing: 1px;
    text-transform: lowercase;
}

.cart-item {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.value-cart-list-item{
    display: flex;
    width: 40px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.quantity-form__value {
    max-width: 3rem;   
}

.input-group-sm>.btn{
    border-radius: 0rem !important;
}

.btn-light{
    background-color: #fff;
}
@media screen and (max-width: 767px){
    .item-image {
        height: 100px;
    } 
    
}
@media screen and (max-width: 575px){
    .item-details-bold-lg, .item-details-lg, .remove-line-item{
        font-size: 14px;
    }
    .item-image img {
        width: 90%;

    }
    
}
@media screen and (max-width: 425px){
    .item-image img {
        width: 100%;

    }
    .item-image{
        padding: 5px;
    }
}

