.wrapper-content {
    max-width: 1200px;    
    
}
.container-main{
    margin-top: 35px;

}

.under-fix-nav{
    position: relative;
    height: 175px !important;
}


.wrapper {
    margin-bottom: 70px;
    margin-top: 40px;
}


.banner-wrapper img{
    display: block;
    height: 280px;
    width: 100%;
    object-fit: cover;
}

.content-catalog-title{
    padding-left: 5px;
    margin: 30px 0px;
    letter-spacing: 2px;
    font-weight: 600;
}
.wrapper-small-add-nav{
    margin: 20px 0px;

}
.small-add-nav{
    font-size: 12px;
    text-transform: uppercase;
    opacity: 0.6;
}
.wrapper-card{
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;    
    text-align: center;
   
}

.wrapper-img-card {
    overflow: hidden;   
    height: 290px; 
  
}
.wrapper-img-card:hover .img-card{
    transform: scale(1.1);
}
.img-card {
    display: block; 
    width: 100%;
    object-fit: cover;
    transition: .30s all;
}

.wrapper-card-content{
    margin-bottom: 5px;
    margin-top: 16px;
    
}

.card-name{
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
   
}

.card-price .product-price{
    font-weight: 600;
    font-size: 16px;
}

.card-price .product-price-sale{
    font-weight: 600;
    font-size: 16px;
    color: red;
    padding-left: 6px;
}



@media screen and (max-width: 1199px) {
    .container {
    max-width: 960px !important;       
}
     .wrapper-img-card{
        max-height: 310px;
        
    }
    .under-fix-nav{
        height: 160px !important;
    }
    

}
@media screen and (max-width: 991px) {
    .container {
    max-width: 720px !important;
        
    }
    .container-main{
        margin-top: 0px;

    }
     .wrapper-img-card{
        max-height: 230px;
        
    }
    .card-name, .product-price-sale{
        font-size: 14px;   
    }
    .card-price .product-price{
        font-size: 14px;
    }
    .under-fix-nav{
        height: 50px !important;
        
    }


    
}

@media screen and (max-width: 767px) {
    .container {
    max-width: 540px !important;
        
}
     .wrapper-img-card{   
        max-height: 260px;
        
    }
    .banner-wrapper img{
        max-height: 220px;
    } 
}

@media screen and (max-width: 575px) {
    .wrapper-img-card{
        max-height: 190px;    
    }

    /* .card-name {
        font-size: 12px; 
          
    }
    .card-price .product-price, .product-price-sale{
        font-size: 13px;
    } */

    .banner-wrapper img{
        max-height: 170px;
    }
    .content-catalog-title{
        font-size: 20px;
        margin: 20px 0px;
    }
   
}





