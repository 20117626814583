
.content-scroll-button{
    overflow-y: scroll; 
}

.scroll-up{
position: fixed; 
width: 50px;  
right: 35px; 
bottom: 35px; 
height: 45px; 
border: 1px solid darkgray;
background-color: #fff;
/* font-size: 3rem;  */
z-index: 100; 
cursor: pointer; 
color: darkgray; 
text-align: center;
vertical-align: middle;
/* padding: 8px 14px; */
}

@media screen and (max-width: 576px){
    .scroll-up{
        width: 45px;  
        right: 30px; 
        bottom: 30px; 
        height: 40px;
    }   
}
@media screen and (min-width: 1600px){
    .scroll-up{
        right: 140px;
    }    
}
@media screen and (min-width: 2000px){
    .scroll-up{
        right: 250px;
    } 
}


