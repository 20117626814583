.app-logo {
    display: block;
    height: 70px;
    width: 100%;
    margin: 0 auto;
}
.shipping-info {
    background-color:#414344;
    color: white;
    font-size: 14px;
    text-align: center;
}

@media (max-width: 992px) {
    .app-logo{
        height:50px;
        width: auto;

    }
    
}