.wrapper-sort{
    background: #fafafa;
    padding: 7px 10px;
   letter-spacing: 1px;
    margin-bottom: 30px;

}

.wrapper-sort .form-select{
    border-radius: 0rem !important;
}
.col-form-label{
    font-size: 14px;

}