.undernav{
    height: 110px;
}
.img-main-page {
    width: 100%;
    height: 660px;
    background: url('./bannerMain4.jpg')top /cover no-repeat ;

}
@media (max-width: 1200px){
    .undernav{
        height: 110px;
    }
}

@media (max-width: 992px){
    .undernav{
        height: 50px;
    }
    .img-main-page{
        max-height: 400px;

    }

}
@media (max-width: 576px){
    .img-main-page{
        max-height: 300px;

    }

}

.wrapper-main-title{
    height: 350px;
}
.main-title {
    width: 300px;
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 1px 1px 3px #000000;
    letter-spacing: 3px;
    margin-bottom: 30px;
}
.main-title::after {
    display: block;
    content: '';
    border-bottom: 1px solid #ffffff;
    width: 250px;
    margin: 0 auto;
    margin-top: 20px;
}

.shop-new-title{
    font-size: 12px;
    margin-bottom: 12px;
    letter-spacing: 1px;

}

.shop-new .btn{
    background-color: #414344 !important ;
    font-size: 16px;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 12px 16px !important;
    border-radius: 0rem;
    border: 2px solid white ;
    /* box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.4); */

}

.shop-new .btn-dark{
    background-color: #414344 !important ;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 12px 16px !important;
    border-radius: 0rem;
    border: 2px solid #414344 ;
}



@media (max-width: 992px){
    .wrapper-main-title {
        height: 320px;
    }
    .main-title{
        font-size: 32px;
        margin-bottom: 20px;

    }
    .main-title::after {
        width: 200px;
    }
    .shop-new .btn{
        font-size: 14px;
        padding: 10px 16px !important;

    }


}
@media (max-width: 767px){
    .main-title{
        font-size: 30px;
    }
    .main-title::after {
        display: none;
    }

}

@media (max-width: 576px){
    .wrapper-main-title{
        height: 250px;
    }
    .main-title{
        font-size: 24px;
    }

}



/* SHOP LIST  */

.shop-list-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 60px;
}

.shop-list-title {
    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}


.wrapper-cards .img-cards {
    display: block;
    /* margin: 0 auto; */
    width: 100%;
    /* height: 340px; */
    object-fit: cover;
    transition: .30s all;
}

.wrapper-cards {
    position: relative;
    overflow: hidden;
    height: 390px;
    margin-bottom: 40px;
}
.wrapper-cards::after{
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-color:rgba(21,24,36,0.4);
    position: absolute;
    top:0;
    left:0;
    z-index: 2;
}

.wrapper-cards:hover .img-cards {
    transform: scale(1.1);
}

.wrapper-cards .lists-title {
    position: absolute;
    top: 45%;
    left: 49%;
    transform: translateX(-45%);
    font-size: 24px;
    letter-spacing: 2px;
    color: white;
    text-shadow: 0.5px 0.5px 3px #000000;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 3;
}

.shop-cards {
    margin-bottom: 45px;
}

@media screen and (max-width: 1199px){
    .wrapper-cards{
        height: 310px;

    }
    .wrapper-cards .lists-title {
        font-size: 22px;
    }

}

@media screen and (max-width: 991px) {
    .wrapper-cards{
        height: 230px;
    }
    .wrapper-cards .lists-title {
        font-size: 20px;
    }
}

 @media screen and (max-width: 767px) {
    .wrapper-cards{
        height: 260px;
        margin-bottom: 30px;
    }
    .shop-list-title h3{
        font-size: 18px;
        margin-top: 40px;
    }

}


@media screen and (max-width: 575px) {
    .wrapper-cards{
        height: 260px;
        margin-bottom: 20px;
    }
    .wrapper-cards .lists-title {
        font-size: 18px;
    }
    .shop-list-wrapper{
        margin-top: 40px;
    }
}
@media screen and (max-width: 425px) {
    .wrapper-cards{
        height: 200px;
        margin-bottom: 20px;
    }
    .wrapper-cards .lists-title {
        font-size: 16px;
    }
}
