
.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: #fff !important;
    /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
}

.accordion-button {
    padding: 0.75rem 1rem;
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 600;
}

.accordion-body{
    font-size: 0.85rem;

}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
    opacity: 1;
        
}

.accordion-button:focus {
    box-shadow:none;
}

.accordion-button::after{
    opacity: 0.5;
}


/* .accordion-button:not(.collapsed)::after{
    background-image: ;
} */