.show-alert {
    position: absolute;
    z-index: 70;
    top: calc(100% + 0px);
    right: 0px;
    background-color: #e3b1c2;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    height: 45px;
    width: 100%;
    margin-bottom: 10px;
    box-shadow: 0 0 5px #e3abbe;
}