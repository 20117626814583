
.wrapper-order-history{
    padding: 20px 40px 1px 40px;
    /* border: 1px solid #ced4da; */
    box-shadow: 0 0 3px #0000001f;
    background-color: #fafafa;
    margin-bottom: 40px;

}
.header-order-history{
    font-size: 20px;
    margin-bottom: 30px;
    margin-left: 10px;
}
@media screen and (max-width: 992px){
    .header-order-history{
        font-size: 18px;
        margin-top: 20px;

    }


}

@media screen and (max-width: 575px){
    .wrapper-order-history{
        padding: 20px 30px 1px 30px;
    }
    

}