.cart-list-header{    
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 25px;  
}
.cart{
    max-width: 980px;
}
.empty-cart{
    height: 50vh;  
    text-transform: uppercase;
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 3px;
    text-align: center;
    margin: 60px 0px;
}
.cart-items-lists{   
    border-top: 1px solid rgba(0, 0, 0, .2);
    margin-bottom: 10px;
}

.totals-lg{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
}
.shipping-line{
    text-transform: uppercase;
    font-size: 12px;
}
.totals-border{
    border-bottom: 1px solid rgba(0, 0, 0, .2);
}

@media screen and (max-width: 575px) {
    .cart-list-header{
        font-size: 14px;

    }
    .empty-cart{
        font-size: 18px;

    }
    .totals-lg{
        font-size: 14px;
    }
    
}