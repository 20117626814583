.search-header{
    border-bottom: 1px solid #e6e6e6;
    text-transform: uppercase;
    font-size: 12px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.wrapper-search-preview{
    margin: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e6e6e6;
}

.search-result-preview{
    box-shadow: 0 0 3px #0000001f;
    padding: 20px 0px;
}

.thumb-search-list{
    height: 160px;
    margin-bottom: 15px;

}
.thumb-search-list img{
    height: 100%;
    width: auto;
    object-fit: cover;
}

.search-preview-name{
    font-size: 12px;
    font-weight: 500;
}
.search-preview-price{
    font-size: 13px;
    font-weight: 500;

}
@media screen and (max-width: 767px){
    .wrapper-search-preview{
         margin: 15px;
    } 
}
@media screen and (max-width: 540px){
    .wrapper-search-preview{
        margin: 0px;
    }
    
}
@media screen and (max-width: 540px){
    .thumb-search-list{
        height: 140px;
    }

}

