
.nav-tabs .nav-link {
    background-color: #f5f5f5 !important;
    color: black !important;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px !important;
    
    /* border-top: solid 1px #dee2e6 !important;
    border-right: solid 1px #dee2e6 !important; */
    padding: 8px 40px;
    border-color: #dee2e6;
   

  }
  
.nav-tabs .nav-link.active {
    /* border-top: solid 1px #dee2e6 !important; */
    /* border-left: solid 1px #dee2e6 !important; */
    background-color: #fff !important;
    
    padding: 8px 40px;
  }
  .tab-content{
    font-size: 13px !important;
  }

  