a{
    text-decoration: none;
    color: black;
}

a:hover{
    color: black;

}

.nnavbar{
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 70;     
}

.nnavbar ul {
    display: flex;   
    justify-content: space-between;
    text-transform: uppercase;  
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0px;
    margin-bottom: 0px;
} 
.nnavbar li a {
    color: white;

}
.wrapper-mobile-nav{
    background: white;
    position: fixed;
    height: 50px;
    top: 0;
    right: 0;
    z-index: 70;
    padding: 0px;
    box-shadow: 0 3px 3px -3px #0000001f;
  
    

}
.pos-rel{
    position: relative;
}
.wrapper-mobile-nav button{
    border: none;
    background-color: #fff;
}

.wrapper-search-mobile{
    position: absolute;
    top: calc(100% + 0px);
    left: 0px;
    width: 82%;
    /* height: 100px; */
    z-index: 40;
    padding: 0px 0px;
}

@media screen and (max-width: 767px){
    .wrapper-search-mobile{
        width: 100%;
        
    }
}




