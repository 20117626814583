.wrapper-terms{
    min-height: 450px;  
    font-size: 14px;
    padding: 25px 10px;
}
.wrapper-terms h5{
    font-size: 18px;
}

@media screen and (max-width: 991px){
    .wrapper-terms{
        font-size: 13px;
    }
    .wrapper-terms h5{
        font-size: 16px;
    }
}

