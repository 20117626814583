.wrapper-burger{
    width: 2rem;
    /* height: 1.25rem; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* position: fixed; */
    /* top: 60px;
    right: 20px; */
    z-index: 20;
    display: none;
  }
  
    @media (max-width: 991px) {
      .wrapper-burger{
      display: flex;
      justify-content: space-around;
      flex-flow: column nowrap;
      }
      
  }
  .burger-closed {
      width: 1.7rem;
      height: 0.15rem;
      background-color: #333;
      /* border-radius: 10px; */
      transform-origin: 1px;
      transition: all 0.3s linear;
  }
  
  .burger-closed:nth-child(2) {
      
      opacity: 1;
  }
  
  /* .burger-open{
      width: 2rem;
      height: 0.25rem;
      background-color: #ccc;
      border-radius: 10px;
      transform-origin: 1px;
      transition: all 0.3s linear;
      z-index: 40;
  }
  .burger-open:nth-child(1){
      transform: rotate(45deg);
  }
  .burger-open:nth-child(2){
      transform: translateX(100%);
      opacity: 0;
  }
  .burger-open:nth-child(3){
      transform: rotate(-45deg);
  } */

  .mobile-close{
      position: fixed;
      top: 20px;
      right: 20px

  }


.mobile-nav-list{   
    flex-flow: column nowrap;
    background-color: #fff;   
    position: fixed;
    z-index: 40;    
    top: 0;
    left: 0;
    height: 100%;
    width: 400px;
    padding-top: 1rem;
    transition: transform 0.3s ease-in-out;

    }
.mobile-nav-list li {        
    padding: 1rem 2rem;
    text-transform: uppercase;
    border-bottom: 1px solid #d9d9d9;
}

.cover{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #232323cc;
    /* opacity: 0.4; */
    z-index: 30;
}

@media (max-width: 576px){
    .mobile-nav-list{
        width: 80%;
    }

}