.order-processing-header{
    font-size: 18px;
    text-transform: uppercase;
    margin: 25px 0px 15px 0px;


}
.wrapping-light{
    background-color: #fafafa;
}
/* .accordion-small{
    margin: 0px 7px;
} */
.accordion-small .accordion-button, .accordion-small .accordion-body {
    background-color: #fafafa !important;
    
}
.accordion-small .accordion-body {
    padding: 1rem 0.6rem;
}



.logo-order-processing img{
    width: 100%;

}

@media screen and (max-width: 991px){
    .order-processing-header{
        font-size: 14px;
    }
    
     
}
    
